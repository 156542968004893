import React from "react"
import styled from "styled-components"
import MediaLink from "./MediaLink"
import { socialMediaLinks } from "../../../customize"

const MediaLinks = () => {
  return (
    <StyledMediaLinks>
      <MediaLink
        accountInfo={socialMediaLinks.email.emailAddress}
        mediaName="Email"
        preHref="mailto:"
      />
      <MediaLink
        accountInfo={socialMediaLinks.github.accountName}
        mediaName="GitHub"
        preHref="https://github.com/"
      />
      <MediaLink
        accountInfo={socialMediaLinks.twitter.accountName}
        mediaName="Twitter"
        preHref="https://twitter.com/"
      />
      <MediaLink
        accountInfo={socialMediaLinks.linkedIn.accountName}
        mediaName="LinkedIn"
        preHref="https://linkedin.com/"
      />
      <MediaLink
        accountInfo={socialMediaLinks.errorism.url}
        mediaName="Errorism"
        preHref=""
      />
    </StyledMediaLinks>
  )
}

export default MediaLinks

const StyledMediaLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  a {
    color: steelblue;
    margin-right: 0.6rem;
    font-size: 0.8rem;
  }
  a:last-of-type {
    margin-right: 0;
  }
`
