import React from "react"
import styled from "styled-components"
import { author } from "../../../customize"

const MainCardName = () => {
  return (
    <StyledMainCardNameAboutDisabled>
      <h2>{author}</h2>
    </StyledMainCardNameAboutDisabled>)
}

export default MainCardName

const StyledMainCardNameAboutDisabled = styled.div`
  display: inline-block;
  h2 {
    padding: 0.2rem 0rem;
  }
`
